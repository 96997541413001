import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  ngIfAnimation,
  ngIfTransformXInverse,
} from '@memberspot/frontend/shared/util/animations';

@Component({
  selector: 'mspot-desktop-sidebar',
  templateUrl: './desktop-sidebar.component.html',
  styles: [],
  animations: [ngIfAnimation, ngIfTransformXInverse],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopSidebarComponent {
  hasTopBanner = input<boolean>(false);
}
