import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
} from '@angular/core';
import {
  ngIfAnimation,
  ngIfOpacity75,
  ngIfTransformXInverse,
} from '@memberspot/frontend/shared/util/animations';
import { BodyService } from '@memberspot/frontend/shared/util/document';

@Component({
  selector: 'mspot-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styles: [],
  animations: [ngIfOpacity75, ngIfAnimation, ngIfTransformXInverse],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSidebarComponent {
  private _opened = false;

  hasBanner = input(false);

  @Input() set opened(val: boolean) {
    this._opened = val;
    this.bodyService.togglePortalOpenClass(val ? 'add' : 'remove');
  }

  get opened() {
    return this._opened;
  }

  @Output() closeSidebar = new EventEmitter();

  constructor(private bodyService: BodyService) {}
}
