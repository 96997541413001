import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  ExamBaseNew,
  ExamManageEndpointsV2,
} from '@memberspot/shared/model/exam';

@Injectable({
  providedIn: 'root',
})
export class AllExamsEndpointService {
  constructor(private http: HttpBackendService) {}

  getAllExams(schoolId: string) {
    return this.http.generic<ExamBaseNew[]>(
      ExamManageEndpointsV2.GET_ALL_EXAMS(schoolId),
    );
  }
}
