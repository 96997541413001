import { Injectable } from '@angular/core';
import { ExamNew } from '@memberspot/shared/model/exam';
import { tap } from 'rxjs';

import { AllExamsEndpointService } from '../all-exams-endpoint.service';
import { AllExamsStore } from './all-exams.store';

@Injectable({
  providedIn: 'root',
})
export class AllExamsService {
  constructor(
    private examsEndpointService: AllExamsEndpointService,
    private store: AllExamsStore,
  ) {}

  sync(schoolId: string) {
    this.store.setLoading(true);

    return this.examsEndpointService.getAllExams(schoolId).pipe(
      tap((exams) => {
        this.store.set(exams);
        this.store.setLoading(false);
      }),
    );
  }

  updateInStore(exam: ExamNew) {
    this.store.upsert(exam.examId, exam);
  }

  removeFromStore(examId: string) {
    this.store.remove(examId);
  }
}
