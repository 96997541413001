import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DesktopSidebarComponent } from './desktop-sidebar/desktop-sidebar.component';
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component';
import { MobileSidebarLinkDirective } from './mobile-sidebar-link.directive';
import { PortalSidebarComponent } from './portal-sidebar/portal-sidebar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MobileSidebarLinkDirective,
    MobileSidebarComponent,
    DesktopSidebarComponent,
    PortalSidebarComponent,
  ],
  exports: [
    MobileSidebarLinkDirective,
    MobileSidebarComponent,
    DesktopSidebarComponent,
    PortalSidebarComponent,
  ],
})
export class FrontendSharedUiSidebarLayoutModule {}
